@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Quicksand, sans-serif;
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.navbar h1{ 
  color: #f1356d;
}

.navbar .links {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover{
  color: #f1356d;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}